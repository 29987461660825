<template>
  <div>
    <div class="order-box" id="invite-award" :class="!isApp ? 'appTop' : 'onlytop'">
      <div class="" v-for="(item, index) in dataList" :key="index">
        <div class="card-item">
          <div class="card-left">
            <!-- <p>已预付「商品名称」</p> -->
            <span class="m-icon">¥</span><span class="m-num">{{ item.price }}</span>
          </div>
          <div class="card-right">
            <!-- <div class="use-btn">未使用</div> -->
            预付款<span class="auto">自动抢购</span>特权
            <van-icon class="icon" name="warning-o" @click="popShow = true" />
          </div>
        </div>
      </div>
      <div v-if="!dataList.length" class="dataNullBox">
        <div>
          <img src="./../imgs/comm/dataNull.png" alt="" srcset="" />
        </div>
        <div class="text">暂无数据</div>
      </div>
      <van-popup class="vantPop" v-model:show="popShow">
        <div class="popup">
          <div class="content">
            <div class="title">预付款购买特权说明</div>
            <div>
              1.预付款是指提前支付全款购买自动抢购特权。当商品开售之后，系统将自动抢购一份商品，发放至“我的-我的藏品”中。同时你还可以继续参与线上抢购。
              <br />
              <br />
              2.若抢购过于火爆，系统未抢购成功，将退回自动抢购特权，可在“我的-我的特权”中查看。未使用的自动抢购特权支持全额退款。
            </div>
          </div>
          <div class="btn">
            <div @click="popShow = false">知道了</div>
          </div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from 'vue';
import PageLoading from '@/components/loading/PageLoading.vue';
import { getuserprivilegelist } from '@/service/order';
import { nftUtils } from '@/utils';
export default {
  components: {
    PageLoading
  },
  setup() {
    const state = reactive({
      isApp: nftUtils.isInApp(),
      dataList: [],
      popShow: false
    });
    onMounted(async () => {
      const { data } = await getuserprivilegelist();
      state.dataList = data;
    });
    return {
      ...toRefs(state)
    };
  }
};
</script>

<style lang="scss" scoped>
@import '../styles/mixin.scss';
.popup {
  width: 300px;
  background: #2f293f;
  border-radius: 8px 8px 8px 8px;
  text-align: left;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;

  .content {
    padding: 16px;
    line-height: 26px;
    .title {
      font-size: 18px;
      font-weight: 500;
      text-align: center;
      margin-bottom: 20px;
    }
  }

  .btn {
    div {
      text-align: center;
      line-height: 50px;
      font-size: 16px;
      border-top: 1px solid #494359;
    }
  }
}
.m-icon {
  color: $primary;
}
.m-num {
  color: $primary;

  font-size: 24px;
  font-weight: 600;
  margin-right: 9px;
  margin-left: 3px;
}
.order-box {
  padding: 0 8px;
  box-sizing: border-box;
}
.icon {
  margin-left: 3px;
}
.appTop {
  padding-top: 70px;
}
.onlytop {
  padding-top: 20px;
}
.card-right {
  font-size: 14px;
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  // margin-top: 38px;
  // margin-left: 20px;
  p {
    margin: 0px;
    font-size: 10px;
    color: rgba(255, 255, 255, 0.7);
  }
  .auto {
    color: #fff0a3;
    font-weight: 500;
  }
}
.card-left {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  margin-left: 8px;
  .use-btn {
    background: #ffffff;
    border-radius: 21px;
    color: #1c172a;
    font-weight: 500;
    padding: 6px 19px;
  }
}
.card-item {
  width: 100%;
  height: 113px;
  background-image: url('../imgs/comm/Slice32.png');
  background-size: 100%;
  color: $fc;
  margin-bottom: 9px;
  display: flex;
}
.dataNullBox {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  img {
    width: 150px;
    height: 110px;
  }

  .text {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 400;
  }
}
</style>
<style lang="scss">
#invite-award {
  .vantPop {
    background: transparent !important;
  }
}
</style>
